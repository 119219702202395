/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from './Link';
import {Spinner} from './Spinner';

export const ButtonTypes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
};

export const ButtonSizes = {
  REGULAR: 'regular',
  LARGE: 'large',
};

export default function Button(props) {
  const {
    children,
    disabled,
    loading,
    onClick,
    className,
    href,
    target,
    style,
    type = 'button',
    buttonType = ButtonTypes.PRIMARY,
    size = ButtonSizes.REGULAR,
  } = props;

  const classes = cx(`
    inline-flex
    items-center
    justify-center
    border
    leading-4
    font-medium
    rounded-md
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    focus:ring-indigo-500`, {
    'border-transparent shadow-sm text-white bg-indigo-600 hover:bg-indigo-700': buttonType === ButtonTypes.PRIMARY && !disabled,
    'border-gray-300 shadow-sm text-gray-700 bg-white hover:bg-gray-50': buttonType === ButtonTypes.SECONDARY && !disabled,
    'border-transparent text-indigo bg-transparent': buttonType === ButtonTypes.TERTIARY && !disabled,
    'bg-gray-300 text-gray-600': (buttonType === ButtonTypes.PRIMARY || buttonType === ButtonTypes.SECONDARY) && disabled,
    'bg-transparent border-transparent text-gray-400': ButtonTypes.TERTIARY && disabled,
    'px-3 py-2 text-sm': size === ButtonSizes.REGULAR,
    'px-5 py-3 text-base': size === ButtonSizes.LARGE,
  }, className);

  if (href) {
    return (
      <Link
        className={classes}
        onClick={onClick || undefined}
        href={href}
        disabled={disabled || loading}
        target={target}
        style={style}
      >
        <>
          {children}
          {loading && <Spinner className="ml-2 border-3 h-4 w-4" />}
        </>
      </Link>
    );
  }

  return (
    <button
      type={type || 'button'}
      className={classes}
      onClick={onClick || undefined}
      disabled={disabled || loading}
      style={style}
    >
      {children}
      {loading && <Spinner className="ml-2 border-3 h-4 w-4" />}
    </button>
  );
}

Button.defaultProps = {
  disabled: false,
  loading: false,
  onClick: undefined,
  className: null,
  href: undefined,
  target: null,
  type: 'button',
  style: undefined,
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.shape(),
};
