import moment from 'moment';

import {createURLWithParams} from '../util';

const ADMIN_URL = '/api/admin';
const AIRTABLE_URL = '/api/airtable';
const BLOG_URL = '/api/blog';
const COACHING_URL = '/api/coaching';
const COMMENTS_URL = '/api/comments';
const COURSE_URL = '/api/courses';
const DATA_URL = '/api/data';
const DUET_URL = '/api/duet';
const EMAIL_URL = '/email';
const FOLLOWS_URL = '/api/follows';
const GIFT_URL = '/api/gifts';
const GUIDES_URL = '/api/guides';
const INTERVIEW_URL = '/api/interviews';
const JOB_REFERRALS_URL = '/api/job_referrals';
const KARMA_URL = '/api/karma';
const LESSON_URL = '/api/lessons';
const PAYMENTS_URL = '/api/payments';
const PRAMP_URL = '/pramp/sso';
const QUESTION_URL = '/api/questions';
const RECOMMENDATIONS_URL = '/api/recommendations';
const REFERRAL_URL = '/api/referrals';
const RESUME_REVIEW_URL = '/api/resume_review';
const SEARCH_URL = '/api/search';
const STREAK_URL = '/api/streak';
const SUBSCRIPTIONS_URL = '/api/subscriptions';
const TAG_URL = '/api/tags';
const USER_URL = '/api/users';
const VOTE_URL = '/api/upvotes';
const ANALYTICS_URL = '/api/analytics';
const CODE_URL = '/api/code';
const ORG_URL = '/api/organization';
const INVITE_URL = '/api/invites';

const defaultGetOptions = () => ({
  method: 'GET',
  credentials: 'same-origin',
  headers: {'Content-Type': 'application/json'},
  retries: 2,
});

const defaultPatchOptions = {
  method: 'PATCH',
  credentials: 'same-origin',
  headers: {'Content-Type': 'application/json'},
  retries: 0,
};

const defaultPostOptions = {
  method: 'POST',
  credentials: 'same-origin',
  headers: {'Content-Type': 'application/json'},
  retries: 0,
};

const defaultDeleteOptions = {
  method: 'DELETE',
  credentials: 'same-origin',
  headers: {'Content-Type': 'application/json'},
  retries: 0,
};

export function createPatchBodyRequest(body) {
  return {...defaultPatchOptions, body: JSON.stringify(body)};
}

export function createPostBodyRequest(body) {
  return {...defaultPostOptions, body: JSON.stringify(body)};
}

export function createDeleteBodyRequest(body) {
  return {...defaultDeleteOptions, body: JSON.stringify(body)};
}

function indexBySlug(dataArray) {
  return dataArray.reduce((result, item) => ({
    ...result,
    [item.slug]: item,
  }), {});
}

export const fetchResource = async (url, _options) => {
  const options = _options || defaultGetOptions();
  try {
    const resp = await fetch(url, {
      credentials: 'same-origin',
      ...options,
      headers: {
        'Local-Date': moment().toISOString(true), // Custom header introduced for calculating streaks
        ...options.headers,
      },
    });
    if (!resp.ok) {
      const err = new Error('Request error:', resp.status);

      if (options && options.retries === 0) {
        const res = await resp.json();
        err.message = res.message;
        err.status = resp.status;
      }

      throw err;
    }
    return await resp.json();
  } catch (err) {
    if (options && options.retries > 0) {
      options.retries -= 1;
      return fetchResource(url, options);
    }
    throw err;
  }
};

/**
 * If content and iv query params are present, pass along to the server
 * so we can pass pendingAccount back to the client
 * @param {String} content
 * @param {String} iv
 */
export const getInitialData = async (content, iv) => {
  const resp = await fetchResource(createURLWithParams(DATA_URL, {content, iv}));
  return resp.data;
};

export const getAirtableData = async (params, type) => {
  const search = new URLSearchParams(params).toString();
  const resp = await fetchResource(`${AIRTABLE_URL}/${type}?${search}`);
  return resp.data;
};

export const getBlogRelatedContent = async (params) => {
  const query = new URLSearchParams(params).toString();
  const resp = await fetchResource(`${BLOG_URL}/related/?${query}`);
  return resp.data;
};

export const getCommentsForQuestion = async (questionID) => {
  const resp = await fetchResource(`${COMMENTS_URL}/?question_id=${questionID}`);
  return resp.data;
};

export const getLatestComments = async (limit) => {
  const resp = await fetchResource(`${COMMENTS_URL}/?limit=${limit}&sortBy=most-recent&includeReplies=false`);
  return resp.data;
};

export const postComment = async (questionID, parentCommentID, data) => {
  const payload = {
    question_id: questionID,
    parent_id: parentCommentID || undefined,
    data,
  };
  const resp = await fetchResource(
    `${COMMENTS_URL}/`,
    createPostBodyRequest(payload),
  );
  return resp.status;
};

export const updateComment = async (commentId, data) => {
  const resp = await fetchResource(`${COMMENTS_URL}/${commentId}`, createPostBodyRequest({data}));
  return resp.status;
};

export const postCommentVote = async (commentID) => {
  const resp = await fetchResource(
    `${VOTE_URL}/comment/${commentID}`,
    defaultPostOptions,
  );
  return resp.status;
};

export const deleteCommentVote = async (commentID) => {
  const resp = await fetchResource(
    `${VOTE_URL}/comment/${commentID}`,
    defaultDeleteOptions,
  );
  return resp.status;
};

export const getCourses = async () => {
  const resp = await fetchResource(COURSE_URL);
  return resp.data;
};

export const getCourse = async (id) => {
  const resp = await fetchResource(`${COURSE_URL}/${id}`);
  return resp.data;
};

export const getLesson = async (lessonSlug, courseSlug, trackSlug, referrer, preview) => {
  const resp = await fetchResource(createURLWithParams(`${LESSON_URL}/${lessonSlug}`, {
    referrer, courseSlug, trackSlug, preview,
  }));
  return resp.data;
};

export const rateLesson = async (lessonSlug, data) => fetchResource(
  `${LESSON_URL}/${lessonSlug}/rating`, createPostBodyRequest(data),
);

export const saveLesson = async (lessonSlug, save) => fetchResource(
  `${LESSON_URL}/${lessonSlug}/save`, createPostBodyRequest({save}),
);

export const getMostRecentLesson = async () => {
  const res = await fetchResource(`${LESSON_URL}/recent`);
  return res.data;
};

export const submitRubric = async (id, data) => {
  const resp = await fetchResource(`${LESSON_URL}/${id}/rubric`,
    createPostBodyRequest({id, data}));
  return resp.data;
};

export const getUser = async (idOrUuid) => {
  const resp = await fetchResource(`${USER_URL}/${idOrUuid}`);
  return resp.data;
};

export const deleteUser = async (password) => {
  const resp = await fetchResource(`${USER_URL}/delete`, createPostBodyRequest({password}));
  return resp.data;
};

export const getUserActivity = async (uuid) => {
  const resp = await fetchResource(`${USER_URL}/${uuid}/activity`);
  return resp.data;
};

export const getUserProfile = async (id) => {
  const resp = await fetchResource(`${USER_URL}/${id}/profile`);
  return resp.data;
};

export const getUserOrgMembership = async () => {
  const resp = await fetchResource(`${USER_URL}/organization`);
  return resp.data;
};

export const updateUserProfile = async (data) => {
  const resp = await fetchResource(`${USER_URL}/profile`, createPostBodyRequest(data));
  return resp.data;
};

export const updateUserAvatar = async (data) => {
  const resp = await fetchResource(`${USER_URL}/avatar`, createPostBodyRequest(data));
  return resp.data;
};

export const updateCurrentUser = async (data) => {
  const resp = await fetchResource(USER_URL, createPostBodyRequest(data));
  return resp.data;
};

export const getOnboardingChecklist = async (uuid) => {
  const resp = await fetchResource(`${USER_URL}/${uuid}/checklist`);
  return resp.data;
};

export const setPassword = async (password) => {
  const resp = await fetchResource(`${USER_URL}/password`, createPostBodyRequest({password}));
  return resp.status;
};

export const resetPassword = async (email) => {
  const resp = await fetchResource('/reset', createPostBodyRequest({email, mode: 'start', redirect: false}));
  return resp.status;
};

export const updateUniversityProfile = async (data) => {
  const resp = await fetchResource(`${USER_URL}/university`, createPostBodyRequest(data));
  return resp.data;
};

export const validateInviteCode = async (code) => {
  const resp = await fetchResource(createURLWithParams(`${INVITE_URL}/validate`, {code}));
  return resp.data;
};

export const redeemInviteCode = async (code) => {
  const resp = await fetchResource(`${INVITE_URL}/redeem`, createPatchBodyRequest({code}));
  return resp.data;
};

/**
 * @param {string} productName name of the product to purchase
 * @param {object} data extra data about the customer or source of purchase
 * @returns {string} checkout session ID
 */
export const createCheckoutSession = async (products, data) => {
  const resp = await fetchResource(`${PAYMENTS_URL}/checkout`, createPostBodyRequest({products, data}));
  return resp.data;
};

/**
 * @param {string} name Title of the class. Multiple instances of the
 * class will likely share this name
 * @param {string} classId id of the row in the Airtable Masterclass
 * table. Represents an instance of a class
 * @returns {string} checkout session ID
 */
export const createMasterclassCheckoutSession = async (name, classId) => {
  const resp = await fetchResource(`${PAYMENTS_URL}/masterclass_checkout`, createPostBodyRequest({name, classId}));
  return resp.data;
};

export const cancelSubscription = async (data) => {
  const resp = await fetchResource(`${PAYMENTS_URL}/subscriptions/cancel`, createPostBodyRequest(data));
  return resp.data;
};

export const extendAndDiscountSubscription = async (data) => {
  const resp = await fetchResource(`${PAYMENTS_URL}/subscriptions/cancel2`, createPostBodyRequest(data));
  return resp.data;
};

export const renewSubscription = async (data) => {
  const resp = await fetchResource(`${PAYMENTS_URL}/subscriptions/renew`, createPostBodyRequest(data));
  return resp.data;
};

export const submitAdditionalFeedback = async (data) => {
  const resp = await fetchResource(`/api/cancellations/${data.id}`, createPatchBodyRequest(data));
  return resp.status;
};

export const upgradeSubscriptionAnnual = async (data) => {
  const resp = await fetchResource(`${PAYMENTS_URL}/subscriptions/upgradeannual`, createPostBodyRequest(data));
  return resp.data;
};

export const signUpForEmail = async (email, track, src) => {
  const resp = await fetchResource(EMAIL_URL, createPostBodyRequest({email, track, src}));
  return resp;
};

// Interviews
export const addInterview = async (data) => {
  const resp = await fetchResource(INTERVIEW_URL, createPostBodyRequest(data));
  return resp.data;
};

// Questions
export const getQuestions = async (filterParams) => {
  const resp = await fetchResource(createURLWithParams(QUESTION_URL, filterParams));
  return resp.data;
};

export const getRandomQuestion = async (filterParams) => {
  const resp = await fetchResource(createURLWithParams(`${QUESTION_URL}/random`, filterParams));
  return resp.data;
};

export const getQuestion = async (id, referrer) => {
  const resp = await fetchResource(createURLWithParams(`${QUESTION_URL}/${id}`, {referrer}));
  return resp.data;
};

export const getInterviewsForQuestion = async (id) => {
  const resp = await fetchResource(`${QUESTION_URL}/${id}/interviews`);
  return resp.data;
};

export const getQuestionsRelatedToId = async (id) => {
  const resp = await fetchResource(`${QUESTION_URL}/${id}/related`);
  return resp.data;
};

export const getMultipleQuestions = async (questions) => {
  const formattedQuestions = questions.join(',');
  const resp = await fetchResource(createURLWithParams(`${QUESTION_URL}/batch`, {questions: formattedQuestions}));
  return resp.data;
};

export const getQuestionsRelatedToString = async (question) => {
  const resp = await fetchResource(createURLWithParams(`${QUESTION_URL}/related`, {question}));
  return resp.data;
};

export const publishQuestion = async (id) => {
  const resp = await fetchResource(`${QUESTION_URL}/${id}/publish`, defaultPostOptions);
  return resp.data;
};

export const upsertQuestion = async (data) => {
  const resp = await fetchResource(QUESTION_URL, createPostBodyRequest(data));
  return resp.data;
};

export const mergeQuestions = async (sourceId, targetId, data) => {
  const resp = await fetchResource(`${QUESTION_URL}/merge/${sourceId}/${targetId}`, createPatchBodyRequest(data));
  return resp;
};

export const deleteQuestion = async (id) => {
  const resp = await fetchResource(`${QUESTION_URL}/${id}`, defaultDeleteOptions);
  return resp.data;
};

export const getSavedContent = async () => {
  const resp = await fetchResource(VOTE_URL);
  return resp.data;
};

export const saveQuestion = async (id) => {
  const resp = await fetchResource(`${VOTE_URL}/question/${id}`, defaultPostOptions);
  return resp.status;
};

export const unsaveQuestion = async (id) => {
  const resp = await fetchResource(`${VOTE_URL}/question/${id}`, defaultDeleteOptions);
  return resp.status;
};

export const getQuestionRating = async (id) => {
  const resp = await fetchResource(createURLWithParams(`${QUESTION_URL}/${id}/rating`));
  return resp.data;
};

export const postQuestionRating = async (id, rating) => {
  const resp = await fetchResource(`${QUESTION_URL}/${id}/rating`, createPostBodyRequest({rating}));
  return resp.data;
};

export const logVideoView = async (id) => {
  const resp = await fetchResource(`${QUESTION_URL}/${id}/watch`, createPostBodyRequest());
  return resp.data;
};

export const getTags = async (params) => {
  const resp = await fetchResource(createURLWithParams(TAG_URL, params));
  return resp.data;
};

export const getTrendingCompanies = async () => {
  const resp = await fetchResource(`${TAG_URL}/trending`);
  return resp.data;
};

// user notifications
export const updateUserNotifications = async (data) => {
  const resp = await fetchResource(`${USER_URL}/notifications`, createPatchBodyRequest(data));
  return resp.data;
};

export const getUserNotifications = async () => {
  const resp = await fetchResource(`${USER_URL}/notifications`);
  return resp.data;
};

export const getUserFollows = async () => {
  const resp = await fetchResource(FOLLOWS_URL);
  return resp.data;
};

export const isFollowing = async (uuidOrTag) => {
  const resp = await fetchResource(`${FOLLOWS_URL}/${uuidOrTag}`);
  return resp.data;
};

export const followUser = async (id) => {
  const resp = await fetchResource(`${FOLLOWS_URL}/user/${id}`, defaultPostOptions);
  return resp.data;
};

export const followTag = async (tag) => {
  const resp = await fetchResource(`${FOLLOWS_URL}/tag/${tag}`, defaultPostOptions);
  return resp.data;
};

export const unfollowUser = async (id) => {
  const resp = await fetchResource(`${FOLLOWS_URL}/user/${id}`, defaultDeleteOptions);
  return resp.data;
};

export const unfollowTag = async (tag) => {
  const resp = await fetchResource(`${FOLLOWS_URL}/tag/${tag}`, defaultDeleteOptions);
  return resp.data;
};

// Coaching Requests
export const getCoachingRequest = async (id) => {
  const resp = await fetchResource(`${COACHING_URL}/${id}`);
  return resp.data;
};

export const getCoachingCredits = async () => {
  const resp = await fetchResource(`${COACHING_URL}/credits`);
  return resp.data;
};

export const getCoachingSession = async (id) => {
  const resp = await fetchResource(`${COACHING_URL}/booking/${id}`);
  return resp.data;
};

export const createCoachBooking = async (data) => {
  const resp = await fetchResource(`${COACHING_URL}/booking`, createPostBodyRequest(data));
  return resp.data;
};

export const leaveCoachingFeedback = async (data) => {
  const resp = await fetchResource(`${COACHING_URL}/booking`, createPatchBodyRequest(data));
  return resp.data;
};

export const updateCoachingSession = async (data) => {
  const resp = await fetchResource(`${COACHING_URL}/booking/edit`, createPatchBodyRequest(data));
  return resp.data;
};

export const cancelCoachingSession = async (data) => {
  const resp = await fetchResource(`${COACHING_URL}/booking/cancel`, createPatchBodyRequest(data));
  return resp.data;
};

export const restoreCoachingSession = async (data) => {
  const resp = await fetchResource(`${COACHING_URL}/booking/restore`, createPatchBodyRequest(data));
  return resp.data;
};

export const getCoaches = async () => {
  const resp = await fetchResource(`${COACHING_URL}/coaches`);
  return resp.data;
};

export const getCoachBySlug = async (slug) => {
  const resp = await fetchResource(`${COACHING_URL}/coaches/${slug}`);
  return resp.data;
};

export const getCoachingDashboardInfo = async (slug) => {
  const resp = await fetchResource(createURLWithParams(`${COACHING_URL}/dashboard`, {coachSlug: slug}));
  return resp.data;
};

// Duet
export const createRequest = async (data) => {
  const resp = await fetchResource(DUET_URL, createPostBodyRequest(data));
  return resp.data;
};

export const cancelRequest = async (data) => {
  const resp = await fetchResource(`${DUET_URL}/cancel`, createPatchBodyRequest(data));
  return resp.data;
};

export const createOnDemandSession = async (data) => {
  const resp = await fetchResource(`${DUET_URL}/create`, createPostBodyRequest(data));
  return resp.data;
};

export const joinRoom = async (roomID, onDemand) => {
  const resp = await fetchResource(createURLWithParams(`${DUET_URL}/join/${roomID}`, {onDemand}));
  return resp.data;
};

export const completeRoom = async (roomSID) => {
  const resp = await fetchResource(`${DUET_URL}/complete/${roomSID}`, createPostBodyRequest());
  return resp.data;
};

export const setRoomJoined = async (roomID) => {
  const resp = await fetchResource(`${DUET_URL}/${roomID}`, createPatchBodyRequest());
  return resp.data;
};

export const addFeedback = async (roomID, onDemand, data) => {
  const resp = await fetchResource(createURLWithParams(`${DUET_URL}/feedback/${roomID}`, {onDemand}), createPatchBodyRequest(data));
  return resp.data;
};

export const rateAIFeedback = async (roomID, data) => {
  const resp = await fetchResource(`${DUET_URL}/feedback/${roomID}/rate`, createPatchBodyRequest(data));
  return resp.data;
};

export const requestIntro = async (roomID) => {
  const resp = await fetchResource(`${DUET_URL}/intro/${roomID}`, createPostBodyRequest());
  return resp.data;
};

export const createAISession = async (data) => {
  const resp = await fetchResource(`${DUET_URL}/ai`, createPostBodyRequest(data));
  return resp.data;
};

export const startAISession = async (roomID) => {
  const resp = await fetchResource(`${DUET_URL}/ai/${roomID}/start`, createPatchBodyRequest());
  return resp.data;
};

export const resetAISession = async (roomID) => {
  const resp = await fetchResource(`${DUET_URL}/ai/${roomID}/reset`, createPatchBodyRequest());
  return resp.data;
};

export const completeAISession = async (roomID) => {
  const resp = await fetchResource(`${DUET_URL}/ai/${roomID}/complete`, createPatchBodyRequest());
  return resp.data;
};

// Guides Requests
export const getGuides = async () => {
  const resp = await fetchResource(`${GUIDES_URL}`);
  return resp.data;
};

export const getGuide = async (slug) => {
  const resp = await fetchResource(`${GUIDES_URL}/${slug}`);
  return resp.data;
};

// User Referrals
export const getPendingReferralRewards = async () => {
  const resp = await fetchResource(`${REFERRAL_URL}/reward/`);
  return resp.data;
};

export const sendReferralInvite = async (emailAddress) => {
  const payload = {
    email: emailAddress,
  };
  const resp = await fetchResource(
    `${REFERRAL_URL}/invite`,
    createPostBodyRequest(payload),
  );
  return resp.status;
};

// Subscriptions request
export const getLatestActiveSubscription = async (userID) => {
  const resp = await fetchResource(`${SUBSCRIPTIONS_URL}/${userID}`);
  return resp.data;
};

// Recommendations requests
export const getRecommendations = async () => {
  const resp = await fetchResource(RECOMMENDATIONS_URL);
  return resp.data;
};

export const getRecommendedCourses = async (filterParams) => {
  const resp = await fetchResource(createURLWithParams(`${RECOMMENDATIONS_URL}/courses`, filterParams));
  return resp.data;
};

export const getRecommendedCoaches = async (filterParams) => {
  const resp = await fetchResource(createURLWithParams(`${RECOMMENDATIONS_URL}/coaches`, filterParams));
  return resp.data;
};

// Search
export const getSearchResults = async (params) => {
  const resp = await fetchResource(createURLWithParams(SEARCH_URL, params));
  return resp.data;
};

// Streaks
export const getCurrentStreak = async (type) => {
  const resp = await fetchResource(createURLWithParams(STREAK_URL, type));
  return resp.data;
};

export const getLongestStreak = async (type) => {
  const resp = await fetchResource(createURLWithParams(`${STREAK_URL}/longest`, type));
  return resp.data;
};

// Gifts
export const validateGiftCardToken = async (token) => {
  const resp = await fetchResource(createURLWithParams(GIFT_URL, {token}));
  return resp.data;
};

export const redeemGiftCardToken = async (token) => {
  const resp = await fetchResource(`${GIFT_URL}/redeem`, createPatchBodyRequest({token}));
  return resp.data;
};

// Code runner
export const runCode = async (body) => fetch(`${CODE_URL}/run`, {
  method: 'post',
  headers: {'Content-Type': 'application/json'},
  body: JSON.stringify(body),
});

export const runSQL = async (data) => {
  const resp = await fetchResource(`${CODE_URL}/sql`, createPostBodyRequest(data));
  return resp.data;
};

// Job Referral Requests
export const createJobReferralRequest = async (data) => {
  const resp = await fetchResource(JOB_REFERRALS_URL, createPostBodyRequest(data));
  return resp.data;
};

export const deleteJobReferralRequest = async (data) => {
  const resp = await fetchResource(`${JOB_REFERRALS_URL}/${data.id}`, createDeleteBodyRequest(data));
  return resp.data;
};

export const getJobReferralRequests = async () => {
  const resp = await fetchResource(JOB_REFERRALS_URL);
  return resp.data;
};

export const getMyJobReferralRequests = async (filterParams) => {
  const resp = await fetchResource(createURLWithParams(`${JOB_REFERRALS_URL}/mine`, filterParams));
  return resp.data;
};

export const createJobReferrerProfile = async (data) => {
  const resp = await fetchResource(`${JOB_REFERRALS_URL}/referrer`, createPostBodyRequest(data));
  return resp.data;
};

export const editJobReferrerProfile = async (data) => {
  const resp = await fetchResource(`${JOB_REFERRALS_URL}/referrer`, createPatchBodyRequest(data));
  return resp.data;
};

export const getJobReferrerProfile = async () => {
  const resp = await fetchResource(`${JOB_REFERRALS_URL}/referrer`);
  return resp.data;
};

export const getJobReferrerProfiles = async () => {
  const resp = await fetchResource(`${JOB_REFERRALS_URL}/admin/referrers`);
  return resp.data;
};

export const updateJobReferrerProfile = async (data) => {
  const resp = await fetchResource(`${JOB_REFERRALS_URL}/admin/referrers/${data.id}`, createPatchBodyRequest(data));
  return resp.data;
};

export const claimJobReferralRequest = async (data) => {
  const resp = await fetchResource(`${JOB_REFERRALS_URL}/${data.id}/claim`, createPatchBodyRequest(data));
  return resp.data;
};

export const unclaimJobReferralRequest = async (data) => {
  const resp = await fetchResource(`${JOB_REFERRALS_URL}/${data.id}/unclaim`, createPatchBodyRequest(data));
  return resp.data;
};

export const emailJobReferralCandidate = async (data) => {
  const resp = await fetchResource(`${JOB_REFERRALS_URL}/${data.id}/email`, createPostBodyRequest(data));
  return resp.data;
};

export const inviteJobReferralCandidate = async (data) => {
  const resp = await fetchResource(`${JOB_REFERRALS_URL}/${data.id}/invite`, createPostBodyRequest(data));
  return resp.data;
};

export const createUserFromPramp = async (data) => {
  const resp = await fetchResource(`${PRAMP_URL}/confirm`, createPostBodyRequest(data));
  return resp.data;
};

// Karma
export const getKarmaLeaders = async (params) => {
  const resp = await fetchResource(createURLWithParams(`${KARMA_URL}/leaders`, params));
  return resp.data;
};

export const getUserKarmaRank = async () => {
  const resp = await fetchResource(`${KARMA_URL}/leaders/mine`);
  return resp.data;
};

// Resume Service
export const createResumeReviewRequest = async (data) => {
  const resp = await fetchResource(RESUME_REVIEW_URL, createPostBodyRequest(data));
  return resp.data;
};

export const submitResumeReview = async (data) => {
  const resp = await fetchResource(`${RESUME_REVIEW_URL}/submit`, createPostBodyRequest(data));
  return resp.data;
};

export const refundResumeReviewer = async (data) => {
  const resp = await fetchResource(`${RESUME_REVIEW_URL}/refund`, createPostBodyRequest(data));
  return resp.data;
};

export const reassignResumeReviewer = async (data) => {
  const resp = await fetchResource(`${RESUME_REVIEW_URL}/reassign`, createPatchBodyRequest(data));
  return resp.data;
};

export const cancelResumeReview = async (data) => {
  const resp = await fetchResource(`${RESUME_REVIEW_URL}/cancel`, createPatchBodyRequest(data));
  return resp.data;
};

export const getAdminUser = async (email) => {
  const resp = await fetchResource(`${ADMIN_URL}/users/${email}`);
  return resp.data;
};

export const updateAdminUser = async (email, data) => {
  const resp = await fetchResource(`${ADMIN_URL}/users/${email}`, createPostBodyRequest(data));
  return resp.data;
};

export const updateCoachingCredits = async (data) => {
  const resp = await fetchResource(`${ADMIN_URL}/coaching_credits`, createPostBodyRequest(data));
  return resp.data;
};

export const getOrganizations = async () => {
  const resp = await fetchResource(`${ADMIN_URL}/organizations`);
  return resp.data;
};

export const getCoachList = async () => {
  const resp = await fetchResource(`${ADMIN_URL}/coaches`);
  return resp.data;
};

export const createAllAccessUser = async (data) => {
  const resp = await fetchResource(`${ADMIN_URL}/grant_access`, createPostBodyRequest(data));
  return resp.data;
};

export const refundDuetSession = async (data) => {
  const resp = await fetchResource(`${ADMIN_URL}/refund_duet_session`, createPostBodyRequest(data));
  return resp.data;
};

export const getAnalyticsDashboard = async () => {
  const resp = await fetchResource(ANALYTICS_URL);
  return resp.data;
};

export const getOrgMembers = async (orgId) => {
  const resp = await fetchResource(createURLWithParams(ORG_URL, {orgId}));
  return resp.data;
};

export const addOrgMembers = async (data) => {
  const resp = await fetchResource(`${ORG_URL}/add`, createPostBodyRequest(data));
  return resp.data;
};
